
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { branchStoreKey, cspStoreKey, emailStoreKey, mobileNoStoreKey, roleIdStoreKey, userIdStoreKey, userNameStoreKey } from 'src/app/common/constant/constant';
import { LoginResponseBody } from '../shared/model/login-response-body';
import { AppStorageService } from './app-storage.service';

import {
  AuthConfig,
  NullValidationHandler,
  OAuthErrorEvent,
  OAuthEvent,
  OAuthService,
  OAuthSuccessEvent,
} from 'angular-oauth2-oidc';
import { Observable } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class AuthService {

  loginResponseBody: LoginResponseBody;

  private loggedInStatus = JSON.parse(localStorage.getItem('loggedIn') || 'false')

  constructor(private http: HttpClient,
    private _appStorageService: AppStorageService,
    private _router: Router,
    private oauthService: OAuthService
  ) {
    // To use Keycloak service
    // this.configure();
  }

  isloggedIn(value: boolean) {
    if (this.loginResponseBody?.loginStatus === "OK") {
      this.loggedInStatus = value;
      return true;
    } else {
      return false;
    }
  }

  isUserLoggedIn(): boolean {
    return JSON.parse(localStorage.getItem('loggedIn') || this.loggedInStatus.toString());
  }

  get currentUserName() {
    return this._appStorageService.getData(userNameStoreKey);
  }

  get currentUserId() {
    return this._appStorageService.getData(userIdStoreKey);
  }

  get currentUserMobileNo() {
    return this._appStorageService.getData(mobileNoStoreKey);
  }

  get currentRoleOid() {
    return this._appStorageService.getData(roleIdStoreKey);
  }

  get currentEmail() {
    return this._appStorageService.getData(emailStoreKey);
  }

  get currentBranch() {
    return this._appStorageService.getData(branchStoreKey);
  }

  get currentCsp() {
    return this._appStorageService.getData(cspStoreKey);
  }

  logout(): void {
      localStorage.clear();
      this._router.navigate(['/']);
    }

  isAdminUser(): boolean {
    if (this.loginResponseBody.loginStatus == "OK") {
      return true;
    }
    return false;
  }




  // OAuth 2 impliment with Keycloak

  // private authConfig: AuthConfig = {
  //   issuer: 'http://localhost:8080/auth/realms/Ampere', 
  //   // issuer: 'http://192.168.0.127:8080/auth/realms/Ampere', 
  //   // issuer: 'http://172.16.4.87:8080/auth/realms/Ampere',
  //   // issuer: 'https://doererp.celloscope.net/auth/realms/Ampere',
  //   redirectUri: window.location.origin + '/callback',
  //   postLogoutRedirectUri: 'http://localhost:4200/',
  //   // postLogoutRedirectUri: 'http://192.168.0.127:4200/', 
  //   // postLogoutRedirectUri: 'https://doererp.celloscope.net/',
  //   clientId: 'doer-erp-client',
  //   scope: 'openid profile email offline_access',
  //   responseType: 'code',
  //   requireHttps: false,
  //   disableAtHashCheck: true,
  //   showDebugInformation: true,

  //   // Only required for SSO
  //   //sessionChecksEnabled: true,
  // };

  // public configure() {
  //   this.oauthService.configure(this.authConfig);
  //   this.oauthService.setupAutomaticSilentRefresh();
  //   this.oauthService.tokenValidationHandler = new NullValidationHandler();
  //   this.oauthService.loadDiscoveryDocumentAndTryLogin();
  // }

  // public redirectOnCallback(): void {
  //   this.oauthService.events.subscribe((event) => {
  //     if (event instanceof OAuthErrorEvent) {
  //       console.error(event);
  //     } else if (event instanceof OAuthSuccessEvent) {
  //       if (event.type === 'token_received' && window.location.href === 'http://localhost:4200/callback') {
  //         this._router.navigate(['/local-storage'])
  //       }
  //       // if (event.type === 'token_received' && window.location.href === 'http://192.168.0.127:4200/callback') {
  //       //   this._router.navigate(['/local-storage'])
  //       // }
  //       // if (event.type === 'token_received' && window.location.href === 'https://doererp.celloscope.net/callback') {
  //       //   this._router.navigate(['/local-storage'])
  //       // }
  //     } else {
  //       console.warn(event);
  //     }
  //   });
  // }

  // public getClaims(): string[] {
  //   const accessToken: string = this.oauthService.getAccessToken();
  //   const tokens: string[] = accessToken.split('.');
  //   const claims = JSON.parse(atob(tokens[1]));
  //   return claims.realm_access.roles;
  // }

  // public getOAuthEvent(): Observable<OAuthEvent> {
  //   return this.oauthService.events;
  // }

  // public isLoggedIn(): boolean {
  //   return this.oauthService.hasValidIdToken();
  // }

  // public isAdmin(): boolean {
  //   return this.getClaims().includes('Admin') ? true : false;
  // }

  // public isAgent(): boolean {
  //   return this.getClaims().includes('Agent') ? true : false;
  // }

  // public isBdex(): boolean {
  //   return this.getClaims().includes('BDEx') ? true : false;
  // }

  // public get username() {
  //   let claims: any = this.oauthService.getIdentityClaims();
  //   return claims ? claims.preferred_username : '';
  // }

  // public get sub() {
  //   let claims: any = this.oauthService.getIdentityClaims();
  //   return claims ? claims.sub : '';
  // }

  // public get idToken() {
  //   let token: any = this.oauthService.getIdToken();
  //   return token ? token : '';
  // }

  // public get accessToken() {
  //   let token: any = this.oauthService.getAccessToken();
  //   return token ? token : '';
  // }

  // public get refreshToken() {
  //   let token: any = this.oauthService.getRefreshToken();
  //   return token ? token : '';
  // }

  // public login() {
  //   this.oauthService.initLoginFlow();
  // }

  // public logout() {
  //   this.oauthService.logOut();
  // }

}
