import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PERFECT_SCROLLBAR_CONFIG } from 'ngx-perfect-scrollbar';
import { PerfectScrollbarConfigInterface } from 'ngx-perfect-scrollbar';
import { HttpBackend, HttpClient, HttpClientModule } from '@angular/common/http';
import { PageNotFoundComponent } from './shared/page-not-found/page-not-found.component';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { TranslateLoader, TranslateModule } from '@ngx-translate/core';
import { ToastrModule } from 'ngx-toastr';
import { CommonLayoutModule } from './common/common-layout.module';
import { LandingPageComponent } from './keycloak/landing-page/landing-page.component';
import { CallbackComponent } from './keycloak/callback/callback.component';
import { OAuthModule } from 'angular-oauth2-oidc';
import { MaterialCustomModule } from './material-custom/material-custom.module';
import { AppStudentClassComponent } from './app-student-class/app-student-class.component';
import { CommonModule } from '@angular/common';
import { FormsModule } from '@angular/forms';
import { SharedModule } from './shared/shared.module';
import { PdfViewerComponent } from './school-erp/common/app-report/shared/dialog/pdfViewer.component';
import { PdfViewerByUrlComponent } from './school-erp/common/app-report/shared/dialog/pdf-viewer-by-url.component';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { NgxSpinnerModule } from 'ngx-spinner';
import { AppReportComponent } from './school-erp/common/app-report/app-report.component';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { NgxImageCompressService } from 'ngx-alldone-image-compress';

const DEFAULT_PERFECT_SCROLLBAR_CONFIG: PerfectScrollbarConfigInterface = {
  suppressScrollX: true
};

export function createTranslateLoader(httpBackend: HttpBackend) {
  return new TranslateHttpLoader(new HttpClient(httpBackend), '../assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    PageNotFoundComponent,
    LandingPageComponent,
    CallbackComponent,
    AppStudentClassComponent,
    PdfViewerComponent,
    PdfViewerByUrlComponent,
    AppReportComponent,

  ],
  imports: [
    CommonModule,
    AppRoutingModule,
    CommonLayoutModule,
    BrowserModule,
    MaterialCustomModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: createTranslateLoader,
        deps: [HttpBackend]
      }
    }),
    // NgCircleProgressModule.forRoot({
    //   // set defaults here
    //   radius: 100,
    //   outerStrokeWidth: 16,
    //   innerStrokeWidth: 8,
    //   outerStrokeColor: "#78C000",
    //   innerStrokeColor: "#C7E596",
    //   animationDuration: 300,
    // }),
    ToastrModule.forRoot(),
    BrowserAnimationsModule,
    HttpClientModule,
    OAuthModule.forRoot({
      resourceServer: {
        // allowedUrls: ['http://localhost:9090/doer-erp/api', 'http://localhost:9050/doer-school-report/api'],

        //new Live URL
        allowedUrls: ['https://doer-school.celloscope.net/doer-erp/api', 'https://doer-school.celloscope.net/doer-school-report/api'],
        // allowedUrls: ['https://doer-school-test.celloscope.net/doer-erp/api','https://doer-school-test.celloscope.net/doer-school-report/api'],
        // allowedUrls: ['https://doer-school-iat.celloscope.net/doer-erp/api'],
        sendAccessToken: true,
      },
    }),
    SharedModule,
    NgxExtendedPdfViewerModule,
    NgxSpinnerModule,
    FormsModule,
    FontAwesomeModule

  ],
  exports: [PdfViewerComponent, PdfViewerByUrlComponent],
  entryComponents: [PdfViewerComponent, PdfViewerByUrlComponent],
  providers: [{
    provide: PERFECT_SCROLLBAR_CONFIG,
    useValue: DEFAULT_PERFECT_SCROLLBAR_CONFIG
  }, NgxImageCompressService],
  bootstrap: [AppComponent]
})
export class AppModule { }
