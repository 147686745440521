export class ResponseHeader {

  requestId: String;
  requestDateTime: String;
  responseDateTime: String;
  responseCode: String;
  remarks: String;
  message: String;
  status: String;
}
