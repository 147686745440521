import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import {
  MatDialog,
  MatDialogConfig,
  MatDialogRef,
} from '@angular/material/dialog';
import { forkJoin, Observable } from 'rxjs';
import { map, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { FeesReportRequest } from '../../../app-school/app-school-report/components/app-school-fees-report/components/shared/request/fees-report-request';
import { PdfViewerByUrlComponent } from '../dialog/pdf-viewer-by-url.component';
import { PdfViewerComponent } from '../dialog/pdfViewer.component';
import { PdfDownloadRequest } from '../pdf-request/pdf-download-request';
import { RequestBodyBuilder } from '../request-body-builder';

@Injectable({
  providedIn: 'root',
})
export class ReportMakerService {
  constructor(
    private reqBodyBuilder: RequestBodyBuilder,
    private httpClient: HttpClient,
    private dialog: MatDialog
  ) { }

  // TODO: make a util class for this particular function
  // public getReports<T>(url: string, rb: RequestBody<T>): Observable<any[]> {
  //   const urls: string[] = [url + 'PDF', url + 'DOC', url + 'XLS'];
  //   const rbs: object[] = [rb, rb, rb];
  //   const res = new Array(urls.length);
  //   urls.forEach((value, index) => {
  //     res[index] = this.httpClient.post(urls[index], rbs[index], { responseType: 'blob' });
  //   });
  //   return forkJoin(res);
  // }

  public getReports<T>(url: string, rb: any): Observable<any[]> {
    // const urls: string[] = [url + 'PDF', url + 'DOC', url + 'XLS'];
    const urls: string[] = [url, url, url];
    const rbs: object[] = [rb, rb, rb];
    const res = new Array(urls.length);
    urls.forEach((value, index) => {
      res[index] = this.httpClient.post(urls[index], rbs[index], {
        responseType: 'blob',
      });
    });
    return forkJoin(res);
  }


  // TODO: throw it into a separate class, preferably a util class
  public downloadAllFormat(
    pdfBlob: any,
    docBlob: any,
    excelBlob: any,
    fileName: string
  ) {
    debugger;
    let pdfArrayBuffer;
    const pdfFileReader = new FileReader();
    pdfFileReader.onload = (evPdf) => {
      pdfArrayBuffer = evPdf.target['result'];
      const pdfData = pdfArrayBuffer;

      let wordArrayBuffer;
      const wordFileReader = new FileReader();
      wordFileReader.onload = (evWord) => {
        wordArrayBuffer = evWord.target['result'];
        const wordData = wordArrayBuffer;

        let excelArrayBuffer;
        const excelFileReader = new FileReader();
        excelFileReader.onload = (evExcel) => {
          excelArrayBuffer = evExcel.target['result'];
          const excelData = excelArrayBuffer;
          this.viewAllFormat(pdfData, wordData, excelData, fileName);
        };
        excelFileReader.readAsArrayBuffer(excelBlob);
      };
      wordFileReader.readAsArrayBuffer(docBlob);
    };
    pdfFileReader.readAsArrayBuffer(pdfBlob);
  }

  viewAllFormat(pdfData: any, docData: any, excelData: any, filename: string) {
    // debugger;
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = false;
    dialogConfig.width = '900px';
    dialogConfig.position = { top: '60px' };
    dialogConfig.closeOnNavigation = true;
    let dialogRef;
    dialogConfig.data = pdfData;
    dialogRef = this.dialog.open(PdfViewerComponent, dialogConfig);
    dialogRef.componentInstance.setFilename(filename);
    dialogRef.componentInstance.setWordBlob(docData);
    dialogRef.componentInstance.setExcelBlob(excelData);
    this.closeEventEmitterSubscriber(dialogRef);
  }

  viewPdfFormat(pdfData: any, docData: any, excelData: any, filename: string) {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = false;
    dialogConfig.width = '900px';
    dialogConfig.position = { top: '60px' };
    dialogConfig.closeOnNavigation = true;
    let dialogRef;
    dialogConfig.data = pdfData;
    dialogRef = this.dialog.open(PdfViewerByUrlComponent, dialogConfig);
    dialogRef.componentInstance.setFilename(filename);
    dialogRef.componentInstance.setWordBlob(docData);
    dialogRef.componentInstance.setExcelBlob(excelData);
    this.closeEventEmitterSubscriber(dialogRef);
  }

  closeEventEmitterSubscriber(dialogRef: MatDialogRef<any, any>) {
    dialogRef.componentInstance.closeEventEmitter.subscribe(
      (res: any) => dialogRef.close(),
      (error: any) => console.log(error)
    );
  }

  getDueFeesReportByStudentId(request: any): Observable<boolean> {
    debugger;
    return this.getReports(`${environment.baseURL}${environment.GET_DUE_FEES_REPORT_BY_STUDENT_ID}`, request).pipe(
      tap(reportData => {
        this.downloadAllFormat(reportData[0], reportData[1], reportData[2], 'due_fees_history_report_by_' + request.body.studentId);
      }),
      map(reportData => reportData != null)
    );
  }

  getExpenseDetailsReport(request: any): Observable<boolean> {
    debugger;
    return this.getReports(`${environment.baseURL}${environment.EXPENSE_DETAILS_REPORT}`, request).pipe(
      tap(reportData => {
        this.downloadAllFormat(reportData[0], reportData[1], reportData[2], 'expense_details_report' + request.body.oid);
      }),
      map(reportData => reportData != null)
    );
  }

  getDueFeesReportByInstituteClassAndSession(request: any): Observable<boolean> {
    debugger;
    return this.getReports(`${environment.baseURL}${environment.GET_DUE_FEES_REPORT_BY_INSTITUTE_CLASS}`, request).pipe(
      tap(reportData => {
        this.downloadAllFormat(reportData[0], reportData[1], reportData[2], 'due_fees_report_by_' + request.body.instituteClassOid);
      }),
      map(reportData => reportData != null)
    );
  }

  getDueFeesHistoryReportByInstituteClassAndSession(request: any): Observable<boolean> {
    debugger;
    return this.getReports(`${environment.baseURL}${environment.GET_DUE_FEES_HISTORY_REPORT_BY_INSTITUTE_CLASS}`, request).pipe(
      tap(reportData => {
        this.downloadAllFormat(reportData[0], reportData[1], reportData[2], 'due_fees_history_report_by_' + request.body.instituteClassOid);
      }),
      map(reportData => reportData != null)
    );
  }


  getFeesCollectionReportByStudentId(request: any): Observable<boolean> {
    debugger;
    return this.getReports(`${environment.baseURL}${environment.GET_FEES_COLLECTION_REPORT_BY_STUDENT_ID}`, request).pipe(
      tap(reportData => {
        this.downloadAllFormat(reportData[0], reportData[1], reportData[2], 'fees_collection_detail_report_by_' + request.body.studentId);
      }),
      map(reportData => reportData != null)
    );
  }

  getincomeCollectionReportByOid(request: any): Observable<boolean> {
    debugger;
    return this.getReports(`${environment.baseURL}${environment.GET_INCOME_COLLECTION_REPORT_BY_OID}`, request).pipe(
      tap(reportData => {
        this.downloadAllFormat(reportData[0], reportData[1], reportData[2], 'income_collection_report_by_' + request.body.oid);
      }),
      map(reportData => reportData != null)
    );
  }


  getincomeCollectionReportByAsset(request: any): Observable<boolean> {
    debugger;
    return this.getReports(`${environment.baseURL}${environment.GET_INCOME_COLLECTION_REPORT_BY_ASSET}`, request).pipe(
      tap(reportData => {
        this.downloadAllFormat(reportData[0], reportData[1], reportData[2], 'income_collection_report_by_' + request.body.oid);
      }),
      map(reportData => reportData != null)
    );
  }


  getincomeCollectionReportByAllAsset(request: any): Observable<boolean> {
    debugger;
    return this.getReports(`${environment.baseURL}${environment.GET_INCOME_COLLECTION_REPORT_BY_ALL_ASSET}`, request).pipe(
      tap(reportData => {
        this.downloadAllFormat(reportData[0], reportData[1], reportData[2], 'income_collection_report_by_' + request.body.oid);
      }),
      map(reportData => reportData != null)
    );
  }


  getFeesCollectionReportByInstituteClassAndSession(request: any): Observable<boolean> {
    return this.getReports(`${environment.baseURL}${environment.GET_FEES_COLLECTION_REPORT_BY_INSTITUTE_CLASS}`, request).pipe(
      tap(reportData => {
        this.downloadAllFormat(reportData[0], reportData[1], reportData[2], 'fees_collection_report_by_' + request.body.instituteClassOid);
      }),
      map(reportData => reportData != null)
    );
  }

  getFeesCollectionDetailReportByInstituteClassAndSession(request: any): Observable<boolean> {
    return this.getReports(`${environment.baseURL}${environment.GET_FEES_COLLECTION_DETAIL_REPORT_BY_INSTITUTE_CLASS}`, request).pipe(
      tap(reportData => {
        this.downloadAllFormat(reportData[0], reportData[1], reportData[2], 'fees_collection_detail_report_by_' + request.body.instituteClassOid);
      }),
      map(reportData => reportData != null)
    );
  }

  getAttendanceReportBySection(request: any): Observable<boolean> {
    // debugger;
    return this.getReports(`${environment.baseURL}${environment.ATTENDANCE_REPORT_BY_SECTION}`, request).pipe(
      tap(reportData => {
        this.downloadAllFormat(reportData[0], reportData[1], reportData[2], 'attendance_report_by_' + request.body.sectionOid);
      }),
      map(reportData => reportData != null)
    );
  }

  getAttendanceReportByStudentOid(request: any): Observable<boolean> {
    debugger;
    return this.getReports(`${environment.baseURL}${environment.ATTENDANCE_REPORT_BY_STUDENT_OID}`, request).pipe(
      tap(reportData => {
        this.downloadAllFormat(reportData[0], reportData[1], reportData[2], 'attendance_report_by_' + request.body.studentId);
      }),
      map(reportData => reportData != null)
    );
  }

  getExamMarkSheetReportByStudentId(request: any): Observable<boolean> {
    // debugger;
    return this.getReports(`${environment.baseURL}${environment.EXAM_MARKSHIT_BY_STUDENT_ID}`, request).pipe(
      tap(reportData => {
        this.downloadAllFormat(reportData[0], reportData[1], reportData[2], 'exam_marksheet_report_by_' + request.body.studentId);
      }),
      map(reportData => reportData != null)
    );
  }

  getStudentListReportByParams(request: any): Observable<boolean> {
    // debugger;
    return this.getReports(`${environment.baseURL}${environment.STUDENT_LIST_REPORT_BY_PARAMS}`, request).pipe(
      tap(reportData => {
        this.downloadAllFormat(reportData[0], reportData[1], reportData[2], 'student_list_report_by_' + request.body);
      }),
      map(reportData => reportData != null)
    );
  }

  getJasperReport(
    requestBody: PdfDownloadRequest,
    url: string,
    downloadedFileName: string
  ): Observable<boolean> {
    console.log(JSON.stringify(requestBody));
    return this.getReports(url, requestBody).pipe(
      tap((reportData) => {
        this.downloadAllFormat(
          reportData[0],
          reportData[1],
          reportData[2],
          downloadedFileName
        );
      }),
      map((reportData) => reportData != null)
    );
  }
}
