import { AuthGuard } from './guards/auth.guard';
import { NgModule } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { PageNotFoundComponent } from './shared/page-not-found/page-not-found.component';
import { LandingPageComponent } from './keycloak/landing-page/landing-page.component';
import { CallbackComponent } from './keycloak/callback/callback.component';
import { LocalStorageComponent } from './common/local-storage/local-storage.component';
import { TranslateModule } from '@ngx-translate/core';


const routes: Routes = [
  // { path: '', redirectTo: 'home', pathMatch: 'full' },
  // { path: '', redirectTo: '/', pathMatch: 'full' },
  { path: 'home', component: LandingPageComponent },
  // { path: '', component: LandingPageComponent},
  { path: 'callback', component: CallbackComponent },
  { path: 'local-storage', component: LocalStorageComponent },



  // {
  //   path: 'dashboard',
  //   loadChildren: () => import('./student-dashboard/student-dashboard.module').then(m => m.StudentDashboardModule),
  //   canActivate: [AuthGuard]
  // },

  {
    path: 'admin',
    loadChildren: () => import('./school-erp/common/app-admin/app-admin.module').then(m => m.AppAdminModule),
    //data: { title: extract('Admin') },
    canActivate: [AuthGuard]
  },
  {
    path: 'principal',
    loadChildren: () => import('./school-erp/common/app-principal/app-principal.module').then(m => m.AppPrincipalModule),
    //data: { title: extract('Admin') },
    canActivate: [AuthGuard]
  },
  {
    path: 'principal-dashboard',
    loadChildren: () => import('./school-erp/common/app-principal/app-principal-dashboard/app-principal-dashboard.module').then(m => m.AppPrincipalDashboardModule),
    //data: { title: extract('Admin') },
    canActivate: [AuthGuard]
  },
  {
    path: 'accountant',
    loadChildren: () => import('./school-erp/common/app-accountant/app-accountant.module').then(m => m.AppAccountantModule),
    //data: { title: extract('Admin') },
    canActivate: [AuthGuard]
  },
  {
    path: 'school',
    loadChildren: () => import('./school-erp/common/app-school/app-school.module').then(m => m.AppSchoolModule),
    //data: { title: extract('School') },
    canActivate: [AuthGuard]
  },
  {
    path: 'teacher',
    loadChildren: () => import('./school-erp/common/app-teacher/app-teacher.module').then(m => m.AppTeacherModule),
    //data: { title: extract('Teacher') },
    canActivate: [AuthGuard]
  },
  {
    path: 'student',
    loadChildren: () => import('./school-erp/common/app-student/app-student.module').then(m => m.AppStudentModule),
    //data: { title: extract('Student') },
    canActivate: [AuthGuard]
  },
  {
    path: 'guardian',
    loadChildren: () => import('./school-erp/common/app-guardian/app-guardian.module').then(m => m.AppGuardianModule),
    //data: { title: extract('Guardian') },
    canActivate: [AuthGuard]
  },
  {
    path: 'staff',
    loadChildren: () => import('./school-erp/common/app-staff/app-staff.module').then(m => m.AppStaffModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'branch-maker',
    loadChildren: () => import('./school-erp/common/app-branch-maker/app-branch-maker.module').then(m => m.AppBranchMakerModule),
    //data: { title: extract('Teacher') },
    canActivate: [AuthGuard]
  },
  {
    path: 'branch-approver',
    loadChildren: () => import('./school-erp/common/app-branch-approver/app-branch-approver.module').then(m => m.AppBranchApproverModule),
    //data: { title: extract('Teacher') },
    canActivate: [AuthGuard]
  },
  {
    path: 'student-dashboard',
    loadChildren: () => import('./student-dashboard/student-dashboard.module').then(m => m.StudentDashboardModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'school-admin-dashboard',
    loadChildren: () => import('./school-admin-dashboard/school-admin-dashboard.module').then(m => m.SchoolAdminDashboardModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'teacher-dashboard',
    loadChildren: () => import('./teacher-dashboard/teacher-dashboard.module').then(m => m.TeacherDashboardModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'login',
    loadChildren: () => import('./login/login/login.module').then(m => m.LoginModule),
  },
  {
    path: 'landingpage',
    loadChildren: () => import('./land-page/land-page.module').then(m => m.LandPageModule)
  },

  { path: '', redirectTo: 'landingpage', pathMatch: 'full' },

  // { path: '', redirectTo: 'login', pathMatch: 'full' },

  { path: '**', component: PageNotFoundComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { relativeLinkResolution: 'legacy' })],
  exports: [RouterModule, TranslateModule]
})
export class AppRoutingModule { }
// function extract(arg0: string): any {
//     throw new Error('Function not implemented.');
// }

export function extract(s: string) {
  return s;
}

